export class httpConfig {
  static backendUrl = process.env.REACT_APP_API;
}
export class httpMethod {
  static get = "GET";
  static post = "POST";
  static put = "PUT";
  static patch = "PATCH";
  static delete = "DELETE";
}
