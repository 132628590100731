import { useEffect } from "react";

export function CloseOutside(
  modalContainerRef: React.MutableRefObject<HTMLDivElement | null>,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContainerRef.current &&
        !modalContainerRef.current.contains(event.target as Node)
      ) {
        setModalOpen(false);
      }
    };

    // Adicione o ouvinte de evento quando o componente for montado
    document.addEventListener("mousedown", handleClickOutside);

    // Remova o ouvinte de evento quando o componente for desmontado
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setModalOpen]);
}
