import { SetStateAction, useEffect, useState } from "react";
import { checkCupons, checkWallet, getHistory } from "../../../services/login";
import { format } from "../../../utils/Utils";
import { CreditCardItem, CreditCardProps } from "../Components/CreditCardItem";
import { DataSquare } from "../Components/DataSquare";
import { NewCreditCardTab } from "../Components/NewCreditCardTab";
import {
  TransactionItem,
  TransactionItemProps,
} from "../Components/TransactionItem";
import { Raffle } from "../PaginaInicio";

export interface Props {}

export interface HistoryData {
  id: number;
  type: string;
  totalValue: string;
  qty: string;
  raffle: Raffle;
  prize: number;
  createdDate: "2024-08-02";
}

export function ConfigPagamento({}: Props) {
  const [shownewcardpage, setShownewcardpage] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);
  const [countActive, setCountActive] = useState<number>(0);
  const [countInactive, setCountInactive] = useState<number>(0);
  const [countTotal, setCountTotal] = useState<number>(0);
  const [historyList, setHistoryList] = useState<HistoryData[]>([]);

  useEffect(() => {
    checkWallet((resp: any) => {
      setBalance(resp.balance);
    });
    checkCupons((resp: any) => {
      setCountActive(resp.countActive);
      setCountInactive(resp.countInactive);
      setCountTotal(resp.countTotal);
    });
    getHistory((resp: any) => {
      const list: HistoryData[] = [];
      for (let index = 0; index < resp.content.length; index++) {
        const element = resp.content[index];
        list.push(element);
      }
      setHistoryList(list);
    });
  }, []);
  function getImageByType(type: string): string {
    if (type.startsWith("RAFFLE_")) {
      return "greenS.png";
    } else {
      return "greenS.png";
    }
  }

  return (
    <div>
      {shownewcardpage ? (
        <NewCreditCardTab setModalOpen={setShownewcardpage} />
      ) : (
        <div></div>
      )}

      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
          borderColor: "white",
          borderStyle: "solid",
          borderWidth: "3px",
          width: "calc(100% - 50px)",
        }}
      >
        <div style={{ fontSize: "20px", display: "flex" }}>
          <div>
            <b>Informações de Pagamento</b>
          </div>
          <img
            src="icon_info.png"
            style={{ paddingLeft: "5px", paddingTop: "5px" }}
          />
        </div>
        <hr color="gray" />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexGrow: "5", marginRight: "30px" }}>
            {/*
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  paddingTop: "6px",
                  paddingRight: "6px",
                }}
              >
                <b>Your Cards</b>
              </div>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setShownewcardpage(true);
                }}
              >
                <div style={{ padding: "5px" }}>New Card</div>{" "}
                <img src="add-square.png" style={{ padding: "5px" }} />
              </div>
            </div>
            <div>
              {cards.map((c, idx) => {
                return (
                  <div key={"card_" + idx}>
                    <CreditCardItem
                      flagImage={c.flagImage}
                      typeCard={c.typeCard}
                      endCard={c.endCard}
                      expCard={c.expCard}
                      ownerCard={c.ownerCard}
                    />
                    <hr />
                  </div>
                );
              })}
            </div>*/}
            <div
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  fontSize: "20px",
                  width: "100%",
                }}
              >
                <b>Últimas Transações</b>
              </div>
              {historyList.map((t, idx) => {
                return (
                  <div key={"trans_" + idx}>
                    <TransactionItem
                      image={getImageByType(t.type)}
                      typeTransaction={t.type}
                      dateTransaction={t.createdDate}
                      valueTransaction={
                        "R$ " +
                        format(
                          Number(("0" + t.totalValue).replace("null", "0")),
                          "Decimal"
                        )
                      }
                    />
                  </div>
                );
              })}
              <div></div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#f4f4f4",
              borderRadius: "10px",
            }}
          >
            <div style={{ margin: "10px" }}>
              <DataSquare
                label={"Crédito Ativo"}
                value={format(balance, "Decimal")}
                img={"sign1.png"}
              />
            </div>
            <div style={{ margin: "10px" }}>
              <DataSquare
                label={"Cupons Ativos"}
                value={format(countActive, "Integer")}
                img={"sign2.png"}
              />
            </div>
            <div style={{ margin: "10px" }}>
              <DataSquare
                label={"Todos os Cupons"}
                value={format(countTotal, "Integer")}
                img={"sign3.png"}
              />
            </div>
            <div style={{ margin: "10px" }}>
              <DataSquare
                label={"Cupons Encerrados"}
                value={format(countInactive, "Integer")}
                img={"sign4.png"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
