interface Props {
  label: string;
  value: string;
  img: string;
  width?: number | undefined;
}
export function DataSquare({ label, value, img, width }: Props) {
  return (
    <div
      style={{
        backgroundColor: "#419802",
        display: "flex",
        justifyContent: "space-between",
        width: width ? width + "px" : "120px",
        borderRadius: "7px",
        paddingRight: "10px",
        paddingLeft: "10px",
      }}
    >
      <div>
        <div style={{ fontSize: "10px", paddingTop: "10px" }}>{label}</div>
        <div style={{ fontSize: "20px" }}>{value}</div>
      </div>
      <div
        style={{
          paddingTop: "12px",
          paddingBottom: "5px",
        }}
      >
        <img
          style={{
            backgroundColor: "white",
            borderRadius: "15px",
            padding: "5px",
            borderColor: "lightgray",
            borderStyle: "solid",
            borderWidth: "1px",
          }}
          src={img}
          width={"20px"}
          height={"20px"}
        />
      </div>
    </div>
  );
}
