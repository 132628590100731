import { useState } from "react";
import "./RoundInput.css";

export interface Props {
  label: string;
  type: string;
  id: string;
  subtext?: string;
  subtextsize?: string;
  editable?: boolean;
  width?: string;
  fontSize?: number;
  labelfontSize?: number;
  labelBtn: string;
  borderColor?: string;
  backgroundColor?: string;
  onChange: (val: any) => void;
}

export function RoundInputUpload({
  label,
  type,
  id,
  subtext,
  subtextsize,
  width = "500px",
  borderColor = "#cccccc",
  backgroundColor = "white",
  fontSize = 14,
  labelfontSize = 14,
  labelBtn,
  onChange,
}: Props) {
  const [showPass, setShowPass] = useState<boolean>(false);
  return (
    <div
      style={{
        display: "flex",
        textAlign: "left",
        fontSize: fontSize + "px",
        paddingBottom: "20px",
        justifyContent: "space-between",
      }}
    >
      <div></div>
      <div
        style={{
          textAlign: "left",
          width: width,
        }}
      >
      <div
        style={{
          fontSize: labelfontSize + "px",
          paddingBottom: "3px",
        }}
      >
        {label}
      </div>
        <div
          style={{
            borderStyle: "solid",
            borderRadius: "25px",
            paddingTop: "10px",
            paddingBottom: "10px",
            display: "flex",
            borderWidth: "1px",
            backgroundColor: backgroundColor,
            borderColor: borderColor,
          }}
        >
          {type === "file" ? (
            <input
            id={id}
            style={{
              background: "transparent",
              backgroundColor: "gray",
              borderStyle: "none",
              borderRadius: "20px",
              paddingLeft: "5px",
              paddingTop: "5px",
              outline: "none",
              outlineColor: "transparent",
              accentColor: "transparent",
              color: "white",
              lightingColor: "transparent",
              marginLeft: "10px",
              marginRight: "10px",
              height: "30px",
              width: "100%",
            }}
            type={type}                
            onChange={onChange}
          />
          ) : (
            <div style={{ paddingLeft: "20px" }}>{labelBtn}</div>
          )}
        </div>
        {subtext === undefined ? (
          <div />
        ) : (
          <div
            style={{
              fontSize: subtextsize === undefined ? "8px" : subtextsize,
              paddingTop: "3px",
            }}
          >
            {subtext}
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
}
