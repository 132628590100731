export function DateSelector() {
  const anos = [];
  var count = new Date().getFullYear() - 17;
  while (count > 1900) {
    anos.push(count);
    count--;
  }
  return (
    <div>
      <div
        style={{
          fontSize: "16px",
          textAlign: "left",
          paddingLeft: "60px",
        }}
      >
        Data de Nascimento
      </div>
      <div>
        <select
          name="dia"
          id="dia"
          style={{
            padding: "10px",
            borderStyle: "solid",
            borderRadius: "15px",
            backgroundColor: "transparent",
            borderColor: "white",
            color: "white",
            marginRight: "20px",
            outline: "white",
          }}
        >
          <option
            style={{
              color: "black",
            }}
            value="01"
          >
            01
          </option>
          <option
            style={{
              color: "black",
            }}
            value="02"
          >
            02
          </option>
          <option
            style={{
              color: "black",
            }}
            value="03"
          >
            03
          </option>
          <option
            style={{
              color: "black",
            }}
            value="04"
          >
            04
          </option>
          <option
            style={{
              color: "black",
            }}
            value="05"
          >
            05
          </option>
          <option
            style={{
              color: "black",
            }}
            value="06"
          >
            06
          </option>
          <option
            style={{
              color: "black",
            }}
            value="07"
          >
            07
          </option>
          <option
            style={{
              color: "black",
            }}
            value="08"
          >
            08
          </option>
          <option
            style={{
              color: "black",
            }}
            value="09"
          >
            09
          </option>
          <option
            style={{
              color: "black",
            }}
            value="10"
          >
            10
          </option>
          <option
            style={{
              color: "black",
            }}
            value="11"
          >
            11
          </option>
          <option
            style={{
              color: "black",
            }}
            value="12"
          >
            12
          </option>
          <option
            style={{
              color: "black",
            }}
            value="13"
          >
            13
          </option>
          <option
            style={{
              color: "black",
            }}
            value="14"
          >
            14
          </option>
          <option
            style={{
              color: "black",
            }}
            value="15"
          >
            15
          </option>
          <option
            style={{
              color: "black",
            }}
            value="16"
          >
            16
          </option>
          <option
            style={{
              color: "black",
            }}
            value="17"
          >
            17
          </option>
          <option
            style={{
              color: "black",
            }}
            value="18"
          >
            18
          </option>
          <option
            style={{
              color: "black",
            }}
            value="19"
          >
            19
          </option>
          <option
            style={{
              color: "black",
            }}
            value="20"
          >
            20
          </option>
          <option
            style={{
              color: "black",
            }}
            value="21"
          >
            21
          </option>
          <option
            style={{
              color: "black",
            }}
            value="22"
          >
            22
          </option>
          <option
            style={{
              color: "black",
            }}
            value="23"
          >
            23
          </option>
          <option
            style={{
              color: "black",
            }}
            value="24"
          >
            24
          </option>
          <option
            style={{
              color: "black",
            }}
            value="25"
          >
            25
          </option>
          <option
            style={{
              color: "black",
            }}
            value="26"
          >
            26
          </option>
          <option
            style={{
              color: "black",
            }}
            value="27"
          >
            27
          </option>
          <option
            style={{
              color: "black",
            }}
            value="28"
          >
            28
          </option>
          <option
            style={{
              color: "black",
            }}
            value="29"
          >
            29
          </option>
          <option
            style={{
              color: "black",
            }}
            value="30"
          >
            30
          </option>
          <option
            style={{
              color: "black",
            }}
            value="31"
          >
            31
          </option>
        </select>
        <select
          style={{
            padding: "10px",
            borderStyle: "solid",
            borderRadius: "15px",
            backgroundColor: "transparent",
            borderColor: "white",
            color: "white",
            marginRight: "20px",
          }}
          name="mes"
          id="mes"
        >
          <option
            style={{
              color: "black",
            }}
            value="01"
          >
            01
          </option>
          <option
            style={{
              color: "black",
            }}
            value="02"
          >
            02
          </option>
          <option
            style={{
              color: "black",
            }}
            value="03"
          >
            03
          </option>
          <option
            style={{
              color: "black",
            }}
            value="04"
          >
            04
          </option>
          <option
            style={{
              color: "black",
            }}
            value="05"
          >
            05
          </option>
          <option
            style={{
              color: "black",
            }}
            value="06"
          >
            06
          </option>
          <option
            style={{
              color: "black",
            }}
            value="07"
          >
            07
          </option>
          <option
            style={{
              color: "black",
            }}
            value="08"
          >
            08
          </option>
          <option
            style={{
              color: "black",
            }}
            value="09"
          >
            09
          </option>
          <option
            style={{
              color: "black",
            }}
            value="10"
          >
            10
          </option>
          <option
            style={{
              color: "black",
            }}
            value="11"
          >
            11
          </option>
          <option
            style={{
              color: "black",
            }}
            value="12"
          >
            12
          </option>
        </select>
        <select
          style={{
            padding: "10px",
            borderStyle: "solid",
            borderRadius: "15px",
            backgroundColor: "transparent",
            borderColor: "white",
            color: "white",
          }}
          name="ano"
          id="ano"
        >
          {anos.map((a, idx) => {
            return (
              <option
                key={"yearOpt" + idx}
                style={{
                  color: "black",
                }}
                value={a}
              >
                {a}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
