interface Props {
  label: string;
  value: string;
  img: string;
  width?: string | undefined;
}
export function BigDataSquare_M({ label, value, img, width }: Props) {
  return (
    <div
      style={{
        backgroundColor: "#419802",
        display: "flex",
        justifyContent: "space-between",
        width: width ? width + "px" : "120px",
        borderRadius: "7px",
        marginRight: "5px",
        marginLeft: "5px",
        paddingRight: "5px",
        paddingLeft: "5px",
        paddingTop: "5px",
        paddingBottom: "5px",
      }}
    >
      <div>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div/>
          <img
          style={{
            backgroundColor: "white",
            borderRadius: "15px",
            padding: "5px",
            borderColor: "lightgray",
            borderStyle: "solid",
            borderWidth: "1px",
          }}
          src={img}
          width={"20px"}
          height={"20px"}
        />
        <div/>
        </div>
        <div
          style={{
            fontSize: "10px",
            paddingBottom: "10px",
            paddingTop: "10px",
            textAlign:"center",
          }}
        >
          {label}
        </div>
        <div
          style={{
            fontSize: "14px",
            paddingBottom: "10px",
            textAlign:"center",
          }}
        >
          {value}
        </div>
      </div>
    </div>
  );
}
