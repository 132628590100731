import { useRef, useState } from "react";
import { CloseOutside } from "./Util";

interface Props {
  title: string;
  buttons: ButtonActionProp[];
}

export interface ButtonActionProp {
  label: string;
  action: () => void;
  inverted?: boolean;
}

export function DropdownMenuButton({ title, buttons }: Props) {
  const [open, setOpen] = useState(false);
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  CloseOutside(modalContainerRef, setOpen);

  if (!open) {
    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          width: "150px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "0px",
            borderRadius: "18px",
            border: "2px solid green",

            backgroundColor: "#00000023",
            backdropFilter: " blur(18px)",

            color: "white",
            paddingLeft: "28px",
            paddingRight: "28px",
          }}
        >
          <button
            style={{
              borderRadius: "18px",
              borderColor: "transparent",
              backgroundColor: "transparent",
              color: "white",
              width: "100%",
              padding: 0,
              margin: 0,

              fontSize: "18px",
              lineHeight: "28px",
            }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {title}
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          width: "150px",
        }}
        ref={modalContainerRef}
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "0px",
            borderRadius: "18px",
            borderColor: "green",
            borderStyle: "solid",
            backgroundColor: "green",
            color: "white",
            paddingLeft: "28px",
            paddingRight: "28px",
          }}
        >
          <button
            style={{
              borderRadius: "18px",
              borderColor: "transparent",
              backgroundColor: "transparent",
              color: "gray",
              width: "100%",
              padding: 0,
              margin: 0,

              fontSize: "18px",
              lineHeight: "28px",
            }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {title}
          </button>
          <div>
            {buttons.map((bt, index) => (
              <div key={title + "_ddb_" + index}>
                {index > 0 ? (
                  <hr
                    style={{ width: "100%", padding: "0px", margin: "0px" }}
                    color="white"
                  />
                ) : (
                  <div />
                )}
                <button
                  style={{
                    borderRadius: "18px",
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    color: "white",
                    width: "100%",
                    fontSize: "18px",
                    lineHeight: "28px",
                    padding: 0,
                    margin: 0,
                  }}
                  onClick={() => {
                    bt.action();
                    setOpen(false);
                  }}
                >
                  {bt.label}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
