import { useEffect } from "react";

const pasteListener = (ev) => {
  ev.preventDefault();
  let paste = ev.clipboardData?.getData("text");
  paste = paste?.toUpperCase();

  let inputs = document.querySelectorAll(".codefield");
  if (paste?.length !== inputs.length) return;
  inputs.forEach((input, index) => {
    input.focus();
    input.value = paste[index];
  });
};

export function CodeFieldSenha({ id }) {
  useEffect(() => {
    document.addEventListener("paste", pasteListener);
    let inputs = document.querySelectorAll(".codefield");
    inputs.forEach((input, index, arr) => {
      input.addEventListener("input", function (ev) {
        arr[index + 1]?.focus();
      });
    });
    return () => {
      document.removeEventListener("paste", pasteListener);
      let inputs = document.querySelectorAll(".codefield");
      inputs.forEach((input, index, arr) => {
        input.removeEventListener("input", function (ev) {
          arr[index + 1]?.focus();
        });
      });
    };
  });

  return (
    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      <input
        id={id + "1"}
        className="codefield"
        maxLength={1}
        style={{ width: "10px" }}
        autoFocus
      />
      <input
        id={id + "2"}
        className="codefield"
        maxLength={1}
        style={{ width: "10px" }}
      />
      <input
        id={id + "3"}
        className="codefield"
        maxLength={1}
        style={{ width: "10px" }}
      />
      <input
        id={id + "4"}
        className="codefield"
        maxLength={1}
        style={{ width: "10px" }}
      />
      <input
        id={id + "5"}
        className="codefield"
        maxLength={1}
        style={{ width: "10px" }}
      />
    </div>
  );
}
