import { Raffle } from "../pages/Main/PaginaInicio";

export class UserToken {
  static login = "";
  static reminder = "";
  static email = "";
  static pass = "";
  static auth = "";
  static remind = "";
}
