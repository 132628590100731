import { useRef, useState } from "react";
import { CloseOutside } from "./Util";

interface Props {
  title: string;
  buttons: string[];
  inverted?: boolean;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}

export function Selection({
  title,
  buttons,
  selectedIndex,
  setSelectedIndex,
}: Props) {
  return (
    <div>
      <div>
        <div style={{ color: "black" }}>{""}</div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "auto",
          padding: "5px",
          borderStyle: "none",
          borderRadius: "15px",
        }}
      >
        <div
          style={{
            color: "white",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <div>
            {buttons.map((bt, index) => (
              <div key={title + "_ddb_" + index} style={{}}>
                <button
                  style={{
                    borderRadius: "10px",
                    borderColor: "transparent",
                    backgroundColor:
                      selectedIndex === index ? "#22AA22" : "#cccccc",
                    color: selectedIndex === index ? "white" : "black",
                    width: "100%",
                    fontSize: "20px",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => {
                    setSelectedIndex(index);
                  }}
                >
                  {bt}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
