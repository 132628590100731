import { pagContato, pagFaq, pagSobre, pagTermos } from "./MainPage";
import pj from "../../../package.json";



export function Rodape(){

    return <div
    style={{
      color: "white",
      justifyContent: "space-around",
      display: "flex",
      paddingTop: "100px",
      fontSize: "20px",
    }}
  >
    <div style={{ width: "10%" }}></div>
    <div style={{ width: "20%", minWidth:"250px" }}>
      <img src="numberwinlogo.png" />
      <div
        style={{
          fontSize: "14px",
          paddingTop: "20px",
          color: "darkgray",
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </div>
      <div style={{ fontSize: "12px", paddingTop: "8px" }}>
        v.{pj.version} {process.env.REACT_APP_ENV}
      </div>
    </div>
    <div style={{ width: "20%" }}>
      <div style={{ paddingBottom: "20px" }}>Fale Conosco</div>
      <div
        style={{
          fontSize: "14px",
          paddingTop: "20px",
          color: "darkgray",
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit
      </div>
    </div>
    <div style={{ width: "20%" }}>
      <div
        style={{ paddingBottom: "10px", cursor: "pointer" }}
        onClick={() => {
          window.location.href =
            window.location.href.substring(
              0,
              window.location.href.indexOf("?")
            ) +
            "?pag=" +
            pagSobre;
        }}
      >
        Sobre a NumberWin
      </div>
      <div
        style={{ paddingBottom: "10px", cursor: "pointer" }}
        onClick={() => {
          window.location.href =
            window.location.href.substring(
              0,
              window.location.href.indexOf("?")
            ) +
            "?pag=" +
            pagFaq;
        }}
      >
        Perguntas frequentes
      </div>
      <div
        style={{ paddingBottom: "10px", cursor: "pointer" }}
        onClick={() => {
          window.location.href =
            window.location.href.substring(
              0,
              window.location.href.indexOf("?")
            ) +
            "?pag=" +
            pagTermos;
        }}
      >
        Termos de Uso e Política de Privacidade
      </div>
      <div
        style={{ paddingBottom: "10px", cursor: "pointer" }}
        onClick={() => {
          window.location.href =
            window.location.href.substring(
              0,
              window.location.href.indexOf("?")
            ) +
            "?pag=" +
            pagContato;
        }}
      >
        Reporte um problema
      </div>
    </div>

    <div style={{ width: "100px" }}>
        <a href="https://www.tiktok.com/@numberwin.app?_t=8nsygo5Lw0s&_r=1">
        <img style={{ paddingLeft: "5px" }} src="tiktok-round-white-icon.png" width={"28px"} height={"28px"}/>
        </a>
        <a href="https://www.instagram.com/numberwin.app?igsh=MWdhZTR6anEzcHI4Ng==">
        <img style={{ paddingLeft: "5px" }} src="icon_instagram.png" />
        </a>
        <a href="https://x.com/numberwin?s=21&t=VgxiP2yN5-Qf80y4u9XSLw">
        <img style={{ paddingLeft: "5px" }} src="x-social-media-white-round-icon.png" width={"28px"} height={"28px"} />
        </a>
    </div>
  </div>
}