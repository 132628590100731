// TODO/HINT Atualizar fontes com base no mockup
const regular = "'D-DIN regular', 'Helvetica', 'Arial', sans-serif";
const exp = "'D-DIN Exp', 'Helvetica', 'Arial', sans-serif";
const condensed = "'D-DIN condensed', 'Helvetica', 'Arial', sans-serif";
const condensedBold =
  "'D-DIN condensed bold', 'Helvetica', 'Arial', sans-serif";
const bold = "'D-DIN bold', 'Helvetica', 'Arial', sans-serif";
const expBold = "'D-DIN Exp bold', 'Helvetica', 'Arial', sans-serif";
const italic = "'D-DIN italic', 'Helvetica', 'Arial', sans-serif";
const expItalic = "'D-DIN Exp italic', 'Helvetica', 'Arial', sans-serif";

const sizes = {
  extraSmall: "0.625rem", // TODO/HINT 10px
  smaller: "0.75rem", // TODO/HINT 12px
  small: "0.875rem", // TODO/HINT 14px
  regular: "1rem", // TODO/HINT 16px
  medium: "1.125rem", // TODO/HINT 18px
  large: "1.375rem", // TODO/HINT 22px
  extraLarge: "2.625rem", // TODO/HINT 42px
};

const fonts = {
  regular,
  exp,
  condensed,
  condensedBold,
  bold,
  expBold,
  italic,
  expItalic,
  sizes,
};

export default fonts;
