import { useEffect, useRef, useState } from "react";
import { Messager } from "../MainPage";
import masks from "../../../utils/masks";
import validations from "../../../utils/validations";
import { RegisterData } from "../PaginaInicio";
import { CheckBox } from "./CheckBox";
import { DateSelector } from "./DateSelector";
import { RoundInput } from "./RoundInput";
import { CloseOutside } from "./Util";
import { checkEmail } from "../../../services/login";
import { RoundWhiteInput } from "./RoundWhiteInput";

export interface Props {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLoginOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setNextPage: React.Dispatch<React.SetStateAction<RegisterData | undefined>>;
}

export function RegisterTab({
  setModalOpen,
  setNextPage,
  setLoginOpen,
}: Props) {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  CloseOutside(modalContainerRef, setModalOpen);

  const [showMessageError, setShowMessageError] = useState<boolean>(false);
  const [nomeColor, setNomeColor] = useState<string>("white");
  const [sobrenomeColor, setSobrenomeColor] = useState<string>("white");
  const [senhaColor, setSenhaColor] = useState<string>("white");
  const [emailColor, setEmailColor] = useState<string>("white");
  const [telefoneColor, setTelefoneColor] = useState<string>("white");
  const [cpfColor, setCpfColor] = useState<string>("white");

  return (
    <div
      style={{
        zIndex: "2000",
        position: "absolute",
        top: "0px",
        right: "0px",
        width: "400px",
        height: "900px",
        backgroundColor: "#22CC2288",
        overflow: "hidden",
      }}
      ref={modalContainerRef}
    >
      {showMessageError && (
        <div
          style={{
            position: "fixed",
            top: "calc(50% - 150px)",
            left: "calc(50% - 100px)",
            width: "300px",
            height: "200px",
            backgroundColor: "black",
            borderRadius: "15px",
            borderColor: "green",
            borderStyle: "solid",
            zIndex: "100000",
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "24px",
              color: "white",
              fontWeight: "bold",
              paddingTop: "10px",
            }}
          >
            {"Erro"}
          </div>
          <div
            style={{
              width: "calc(100% - 20px)",
              height: "90px",
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "10px",
              fontSize: "16px",
              color: "white",
            }}
          >
            {"Email já cadastrado!"}
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <button
              style={{
                padding: "10px",
                backgroundColor: "green",
                color: "white",
                borderRadius: "5px",
                borderColor: "transparent",
                borderWidth: "1px",
                width: "80px",
              }}
              onClick={() => {
                setShowMessageError(false);
              }}
            >
              {"OK"}
            </button>
          </div>
        </div>
      )}
      <div style={{ position: "relative" }}>
        <div
          className="blurry"
          style={{
            width: "400px",
            height: "800px",
            borderColor: "transparent",
            position: "absolute",
            zIndex: 0,
          }}
        ></div>
        <div
          style={{
            zIndex: 0,
            position: "absolute",
            width: "100%",
            color: "white",
            paddingTop: "50px",
            fontSize: "24px",
            textAlign: "center",
          }}
        >
          <div style={{ paddingBottom: "20px" }}>Registre-se</div>
          <RoundWhiteInput
            label="Nome"
            type="text"
            id="nome"
            borderColor={nomeColor}
          />
          <RoundWhiteInput
            label="Sobrenome"
            type="text"
            id="sobrenome"
            borderColor={sobrenomeColor}
          />
          <RoundWhiteInput
            validation={validations.isPassword}
            label="Senha"
            type="password"
            id="pass"
            subtext="Deve conter no mínimo 6 caracteres"
            borderColor={senhaColor}
          />
          <RoundWhiteInput
            label="E-mail"
            type="text"
            id="email"
            validation={validations.isEmailValid}
            borderColor={emailColor}
          />
          <RoundWhiteInput
            validation={validations.checkPhone}
            label="Telefone"
            type="text"
            id="fone"
            mask={masks.formatPhoneNumber}
            borderColor={telefoneColor}
          />
          <RoundWhiteInput
            validation={validations.validateCPF}
            label="CPF"
            type="cpf"
            id="cpf"
            borderColor={cpfColor}
          />
          <DateSelector />

          <div style={{ paddingBottom: "20px" }}></div>
          <button
            style={{
              borderRadius: "25px",
              padding: "10px",
              width: "280px",
              backgroundColor: "black",
              color: "white",
              fontWeight: "bold",
              fontSize: "20px",
              borderColor: "lightgray",
              borderStyle: "solid",
              borderWidth: "1px",
            }}
            onClick={() => {
              //    const remember = (document.getElementById("remember") as HTMLInputElement).checked;
              //const login = (document.getElementById("login") as HTMLInputElement).value;
              const reg: RegisterData = {
                nome: (document.getElementById("nome") as HTMLInputElement)
                  .value,
                sobrenome: (
                  document.getElementById("sobrenome") as HTMLInputElement
                ).value,
                senha: (document.getElementById("pass") as HTMLInputElement)
                  .value,
                email: (document.getElementById("email") as HTMLInputElement)
                  .value,
                telefone: masks.removeMaks(
                  (document.getElementById("fone") as HTMLInputElement).value
                ),
                cpf: masks.removeMaks(
                  (document.getElementById("cpf") as HTMLInputElement).value
                ),
                dia: (document.getElementById("dia") as HTMLInputElement).value,
                mes: (document.getElementById("mes") as HTMLInputElement).value,
                ano: (document.getElementById("ano") as HTMLInputElement).value,
              };

              if (
                reg.nome &&
                reg.sobrenome &&
                reg.senha &&
                !Boolean(validations.isPassword(reg.senha)) &&
                reg.email &&
                !Boolean(validations.isEmailValid(reg.email)) &&
                reg.telefone &&
                !Boolean(validations.checkPhone(reg.telefone)) &&
                reg.cpf &&
                !Boolean(validations.validateCPF(reg.cpf)) &&
                reg.dia &&
                reg.mes &&
                reg.ano
              ) {
                //TODO
                checkEmail(reg.email, (result: any) => {
                  if (result) {
                    setNextPage(reg);
                  } else {
                    setEmailColor("red");
                    setShowMessageError(true);
                  }
                });
              }
              if (!reg.nome) {
                setNomeColor("red");
              } else {
                setNomeColor("white");
              }
              if (!reg.sobrenome) {
                setSobrenomeColor("red");
              } else {
                setSobrenomeColor("white");
              }
              if (!reg.senha || Boolean(validations.isPassword(reg.senha))) {
                setSenhaColor("red");
              } else {
                setSenhaColor("white");
              }
              if (!reg.email || Boolean(validations.isPassword(reg.senha))) {
                setEmailColor("red");
              } else {
                setEmailColor("white");
              }
              if (
                !reg.telefone ||
                Boolean(validations.checkPhone(reg.telefone))
              ) {
                setTelefoneColor("red");
              } else {
                setTelefoneColor("white");
              }
              if (!reg.cpf || Boolean(validations.validateCPF(reg.cpf))) {
                setCpfColor("red");
              } else {
                setCpfColor("white");
              }
            }}
          >
            {" "}
            Próximo
          </button>
          <hr
            style={{ width: "200px", height: "1px", borderStyle: "none" }}
            color="#CCCCCCAA"
          />
          <div style={{ fontSize: "15px" }}>
            Já possui uma conta?{" "}
            <button
              style={{
                backgroundColor: "transparent",
                borderStyle: "none",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={() => {
                setModalOpen(false);
                setLoginOpen(true);
              }}
            >
              Entre Agora
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
