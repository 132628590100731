import { useEffect, useRef, useState } from "react";
import { format } from "../../../utils/Utils";
import { buyCupom, CampaignCategory, checkWallet } from "../../../services/login";
import { BlackTicket } from "../Components/BlackTicket";
import { CheckBox } from "../Components/CheckBox";
import { RoundInput } from "../Components/RoundInput";
import { Ticket } from "../Components/Ticket";
import { Messager } from "../MainPage";
import { Raffle } from "../PaginaInicio";
import { ModalDeposito } from "../Profile/ModalDeposito";

export interface Props {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;

  raffle: Raffle;
  count: number;
}

export function ModalCompra_M({ setModalOpen, raffle, count }: Props) {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContainerRef.current &&
        !modalContainerRef.current.contains(event.target as Node)
      ) {
        setModalOpen(false);
      }
    };

    // Adicione o ouvinte de evento quando o componente for montado
    document.addEventListener("mousedown", handleClickOutside);

    // Remova o ouvinte de evento quando o componente for desmontado
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setModalOpen]);
  const [stage, setStage] = useState<number>(0);
  const [stageData, setStageData] = useState<
    {
      codigo: string;
      nome: string;
      xbtermos: boolean;
      xbprivacidado: boolean;
    }[]
  >([]);

  const [message, setMessage] = useState<string>("");
  const [wallet, setWallet] = useState<number>();
  const [campaignCodeColor, setCampaignCodeColor] = useState<string>("transparent");
  const [nameColor, setNameColor] = useState<string>("transparent");
  const [colorXBtermos, setColorXBtermos] = useState<string>("black");
  const [colorXBpolicita, setColorXBpolitica] = useState<string>("black");

  useEffect(() => {
    checkWallet((resp: any) => {
      resp.balance && setWallet(resp.balance);
    });
  }, []);

  if (stageData.length === 0) {
    const mystageData = [];
    for (let index = 0; index < count; index++) {
      mystageData.push({
        codigo: "",
        nome: "",
        xbtermos: false,
        xbprivacidado: false,
      });
    }
    setStageData(mystageData);
  }

  return (
    <div>
      <div
        style={{
          zIndex: "2500",
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
          backgroundColor: "#00000088",
          overflow: "hidden",
        }}
      ></div>
      <div
        style={{
          zIndex: "3000",
          position: "fixed",
          top: "100px",
          left: "Calc(50% - 150px)",
          width: "300px",
          height: stage === 1 ?"550px":"600px",
          backgroundColor: "#EEE9E3",
          borderRadius: "20px",
          borderColor: "transparent",
          borderStyle: "solid",
          overflow: "hidden",
          fontSize: "30px",
        }}
        ref={modalContainerRef}
      >
        {stage === 0 ? (
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "20px",
              }}
            >
              <div></div>
              <img src="g.png" />
              <div></div>
            </div>
            <div style={{ fontSize: "16px", textAlign: "center" }}>
              <b>Preencha os dados do cupom</b>
            </div>
            <hr style={{ borderWidth: "2px", borderStyle: "solid" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <BlackTicket topline={"XXXXXXX"} bottomline={"Código do cupom"} />
              <div></div>
            </div>
            <div style={{ overflowY: "auto", height: "200px", width: "100%" }}>
              {stageData?.map((sd, idx) => {
                return (
                  <div>
                    <RoundInput
                      label={
                        "Preencha com seu " + CampaignCategory.translateInv(raffle.campaign)?.toLocaleLowerCase()
                      }
                      type={raffle.campaign}
                      id={"tfcodigo" + idx}
                      labelfontSize={14}
                      fontSize={16}
                      defaultValue={sd.codigo}
                      borderColor={campaignCodeColor}
                    />
                    <RoundInput
                      label={"Nome Completo"}
                      type={"text"}
                      id={"tfnome" + idx}
                      labelfontSize={14}
                      fontSize={16}
                      defaultValue={sd.nome}
                      borderColor={nameColor}
                    />
                  </div>
                );
              })}
            </div>

            <CheckBox
              label="*Declaro que li e compreendi os "
              link="termos de uso"
              labelColor={colorXBtermos}
              linkColor={colorXBtermos}
              fontSize={10}
              linkAction={() => {
                window
                  .open(
                    window.location.href.substring(
                      0,
                      window.location.href.indexOf("?")
                    ) + "?pag=6",
                    "_blank"
                  )
                  ?.focus();
              }}
              id="xbtermos"
            />
            <CheckBox
              label="*Concordo com a "
              link="política de privacidade"
              labelColor={colorXBpolicita}
              linkColor={colorXBpolicita}
              fontSize={10}
              linkAction={() => {
                window
                  .open(
                    window.location.href.substring(
                      0,
                      window.location.href.indexOf("?")
                    ) + "?pag=6",
                    "_blank"
                  )
                  ?.focus();
              }}
              id="xbprivacidade"
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <div
                style={{
                  backgroundColor: "green",
                  color: "white",
                  justifyContent: "space-between",
                  width: "50%",
                  margin: "20px",
                  fontSize: "20px",
                  padding: "5px",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                  borderRadius: "45px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  var nomes = true;
                  var codigos = true;
                  var termos = true;
                  var privacidade = true;
                  for (let index = 0; index < count; index++) {
                    stageData[index].codigo = (
                      document.getElementById(
                        "tfcodigo" + index
                      ) as HTMLInputElement
                    ).value;
                    stageData[index].nome = (
                      document.getElementById(
                        "tfnome" + index
                      ) as HTMLInputElement
                    ).value;
                    stageData[index].xbtermos = (
                      document.getElementById("xbtermos") as HTMLInputElement
                    ).checked;
                    stageData[index].xbprivacidado = (
                      document.getElementById(
                        "xbprivacidade"
                      ) as HTMLInputElement
                    ).checked;
                    nomes = nomes && stageData[index].nome.length > 0;
                    codigos = codigos && stageData[index].codigo.length > 0;
                  }
                  termos = stageData[0].xbtermos;
                  privacidade = stageData[0].xbprivacidado;
                  if(termos){
                    setColorXBtermos("black");
                  }else{
                    setColorXBtermos("red");
                  }
                  if(privacidade){
                    setColorXBpolitica("black");
                  }else{
                    setColorXBpolitica("red");
                  }
                  if(nomes){
                    setNameColor("transparent");
                  }else{
                    setNameColor("red");
                  }
                  if(codigos){
                    setCampaignCodeColor("transparent");
                  }else{
                    setCampaignCodeColor("red");
                  }
                  if (nomes && codigos && termos && privacidade) {
                    setStage(1);
                  }
                }}
              >
                Próximo
              </div>
              <div></div>
            </div>
          </div>
        ) : stage === 1 ? (
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "20px",
              }}
            >
              <div></div>
              <img src="g.png" />
              <div></div>
            </div>
            <div style={{ fontSize: "16px", textAlign: "center" }}>
              <b>
                Confirme os dados do{count > 1 ? "s" : null} cupo
                {count > 1 ? "m" : "ns"}
              </b>
            </div>
            <hr style={{ borderWidth: "2px", borderStyle: "solid" }} />
            <div style={{ overflowY: "auto", height: "200px", width: "100%" }}>
              {stageData.map((bt, index) => {
                return (
                  <div
                    key={"bt" + index}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div></div>

                    <BlackTicket topline={bt.codigo} bottomline={bt.nome} />
                    <div></div>
                  </div>
                );
              })}
            </div>
            <div style={{ fontSize: "16px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Saldo disponível:</div>
                <div>{format(wallet ? wallet : 0, "Decimal")}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Preço por Ticket:</div>
                <div>{format(raffle.couponPriceValue, "Decimal")}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Número de Tickets:</div>
                <div>{count}</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Total a pagar:</div>
                <div>{format(raffle.couponPriceValue * count, "Decimal")}</div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <div
                style={{
                  backgroundColor: "green",
                  color: "white",
                  justifyContent: "space-between",
                  width: "50%",
                  margin: "20px",
                  fontSize: "20px",
                  padding: "5px",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                  borderRadius: "45px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const codes = [];
                  for (let index = 0; index < stageData.length; index++) {
                    codes.push({
                      value: stageData[index].codigo,
                      fullName: stageData[index].nome,
                    });
                  }

                  buyCupom(raffle.id, codes, (resp: any) => {
                    if (resp.log) {
                      if(resp.log.indexOf("Valor do cupom inválido")!=-1){
                        setMessage(resp.log);
                        setStage(2);
                      }else{
                        Messager.message("Erro", resp.log, true);
                        setModalOpen(false);
                      }
                    } else {
                      setStage(3);
                    }
                  });
                }}
              >
                Confirmar
              </div>
              <div></div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <div
                style={{
                  backgroundColor: "green",
                  color: "white",
                  justifyContent: "space-between",
                  width: "50%",
                  margin: "20px",
                  fontSize: "20px",
                  padding: "5px",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                  borderRadius: "45px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setStage(0);
                }}
              >
                Voltar
              </div>
              <div></div>
            </div>
          </div>
        ) : stage === 2 ? (
          <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "20px",
              }}
            >
              <div></div>
              <img src="g.png" />
              <div></div>
            </div>
            <div style={{ fontSize: "20px", textAlign: "center" }}>
              <b>
                Ocorreu um erro!
              </b>
            </div>
            <hr style={{ borderWidth: "2px", borderStyle: "solid" }} />
            <div style={{ fontSize: "16px", textAlign: "center" }}>
              {message}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <div
                style={{
                  backgroundColor: "green",
                  color: "white",
                  justifyContent: "space-between",
                  width: "50%",
                  marginTop: "20px",
                  marginBottom: "10px",
                  fontSize: "20px",
                  padding: "5px",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                  borderRadius: "45px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setStage(0);
                }}
              >
                Voltar
              </div>
              <div></div>
            </div>
          </div>
        ) : (
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "20px",
              }}
            >
              <div></div>
              <img src="g.png" />
              <div></div>
            </div>
            <div style={{ fontSize: "20px", textAlign: "center" }}>
              <b>Compra Realizada!</b>
            </div>
            <hr style={{ borderWidth: "2px", borderStyle: "solid" }} />

            <div style={{ fontSize: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  textAlign: "center",
                }}
              >
                <div></div>
                <div>
                  <img src="gcheck.png" />
                </div>
                <div></div>
              </div>
            </div>
            <div style={{ fontSize: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  textAlign: "center",
                }}
              >
                <div></div>
                <div>
                  <b>Compra realizada com sucesso!</b>
                </div>
                <div></div>
              </div>
            </div>
            <div style={{ fontSize: "16px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  textAlign: "center",
                }}
              >
                <div></div>
                <div>Acesse os cupons no seu perfil!!</div>
                <div></div>
              </div>
            </div>
            <div style={{ fontSize: "18px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  textAlign: "center",
                }}
              >
                <div></div>
                <div>Boa Sorte!!!</div>
                <div></div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <hr
                  style={{
                    borderWidth: "2px",
                    borderStyle: "solid",
                    width: "35px",
                  }}
                />
              </div>
              <div>Novos Cupons</div>
              <div>
                <hr
                  style={{
                    borderWidth: "2px",
                    borderStyle: "solid",
                    width: "35px",
                  }}
                />
              </div>
            </div>
            <div style={{ overflowY: "auto", height: "120px", width: "100%" }}>
              {stageData.map((bt, index) => {
                return (
                  <div
                    key={"bt" + index}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div></div>

                    <BlackTicket topline={bt.codigo} bottomline={bt.nome} />
                    <div style={{ color: "transparent" }}>{";)"}</div>
                  </div>
                );
              })}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <div
                style={{
                  backgroundColor: "green",
                  color: "white",
                  justifyContent: "space-between",
                  width: "50%",
                  margin: "20px",
                  fontSize: "20px",
                  padding: "5px",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                  borderRadius: "45px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                Fechar
              </div>
              <div></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
