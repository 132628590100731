export function colorToRGBString(color: string) {
  const red = chartonumber(color.charAt(1)) * 16;
  const red2 = chartonumber(color.charAt(2));
  const green = chartonumber(color.charAt(3)) * 16;
  const green2 = chartonumber(color.charAt(4));
  const blue = chartonumber(color.charAt(5)) * 16;
  const blue2 = chartonumber(color.charAt(6));
  const colorRGB = red + red2 + ", " + (green + green2) + ", " + (blue + blue2);
  return colorRGB;
}
export function chartonumber(ch: string) {
  if (ch === "A" || ch === "a") {
    return 10;
  }
  if (ch === "B" || ch === "b") {
    return 11;
  }
  if (ch === "C" || ch === "c") {
    return 12;
  }
  if (ch === "D" || ch === "d") {
    return 13;
  }
  if (ch === "E" || ch === "e") {
    return 14;
  }
  if (ch === "F" || ch === "f") {
    return 15;
  }
  return parseInt(ch);
}
export function numbertochar(ch: number) {
  if (ch === 10) {
    return "A";
  }
  if (ch === 11) {
    return "B";
  }
  if (ch === 12) {
    return "C";
  }
  if (ch === 13) {
    return "D";
  }
  if (ch === 14) {
    return "E";
  }
  if (ch === 15) {
    return "F";
  }
  return ch + "";
}

export function convertToHex(num: number): string {
  //só funca até 255. Usado pra cores
  num = Math.floor(num);
  const second = num % 16;
  const first = (num - second) / 16;
  return numbertochar(first) + numbertochar(second);
}

export function format(num: number, unit: string): string {
  if (unit === "Inteiro" || unit === "Integer") {
    return Math.floor(num).toLocaleString("pt");
  } else if (unit === "Moeda") {
    const n = (Math.floor(num * 100) / 100).toLocaleString("pt");
    if (n.indexOf(",") === -1) {
      return "R$ " + n + ",00";
    } else if (n.indexOf(",") === n.length - 2) {
      return "R$ " + n + "0";
    } else {
      return "R$ " + n;
    }
  } else if (unit === "Decimal") {
    const n = (Math.floor(num * 100) / 100).toLocaleString("pt");
    if (n.indexOf(",") === -1) {
      return "" + n + ",00";
    } else if (n.indexOf(",") === n.length - 2) {
      return "" + n + "0";
    } else {
      return "" + n;
    }
  } else {
    //caso não tenha unidade, temporariamente trata como decimal
    try {
      const n = (Math.floor(num * 100) / 100).toLocaleString("pt");
      if (n.indexOf(",") === -1) {
        return "" + n + ",00";
      } else if (n.indexOf(",") === n.length - 2) {
        return "" + n + "0";
      } else {
        return "" + n;
      }
    } catch (error) {
      console.log(error);
    }
  }
  return num + "";
}

export const addressFormat = (addressArr: string) => {
  // Elimina espaços duplos
  addressArr = (addressArr + "").replaceAll("  ", " ");
  // Converte todos os elementos do array para minúsculas
  const str = addressArr.toLowerCase();

  // Divide a string em um array
  const arr = str.split(",");

  // Mapeia e capitaliza a primeira letra de cada palavra
  const capitalizedArr = arr.map((word) => {
    word = word.charAt(0).toUpperCase() + word.slice(1);
    return word;
  });

  // Desestrutura o array em variáveis com nomes significativos
  const [
    street,
    streetName,
    number,
    complement,
    district,
    zipcode,
    state,
    city,
  ] = capitalizedArr;

  // Constrói a string de endereço formatada
  let formattedAddress = `${street} ${streetName}, ${number}`;

  if (complement !== "") {
    formattedAddress += ` - ${complement}`;
  }

  formattedAddress += ` - ${district} - ${city} - ${(
    state + ""
  ).toUpperCase()} - ${zipcode}`;
  formattedAddress = formattedAddress.replace(" -  - ", " - ");

  return formattedAddress;
};

export function dateFormat(date: string) {
  //ex 20200819 to 19/08/2020
  if (date.length < 8) {
    return date;
  }
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  return `${day}/${month}/${year}`;
}

export function deselectText() {
  if (window.getSelection) {
    if (window.getSelection()?.empty) {
      // Chrome, Firefox, Safari, Opera
      window.getSelection()?.empty();
    } else if (window.getSelection()?.removeAllRanges) {
      // IE
      window.getSelection()?.removeAllRanges();
    }
  } else if (document.getSelection) {
    // Other browsers
    document.getSelection()?.empty();
  }
}
