import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";
import { parseJwt, useAuth } from "../../../contexts/AuthContext";
import { doLogin, secondStep, sendPasswordResetCode, sendPasswordResetConfirmation, sendPasswordResetRequest } from "../../../services/login";
import { UserToken } from "../../../services/UserToken";
import { CheckBox } from "./CheckBox";
import { CodeField } from "./Codefield";
import { RoundWhiteInput } from "./RoundWhiteInput";
import { CodeFieldSenha } from "./CodefieldSenha";
import { Messager } from "../MainPage";

export interface Props {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRegisterOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function LoginTab({ setModalOpen, setRegisterOpen }: Props) {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  const [doisFatores, setDoisFatores] = useState<boolean>(false);
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const [conferePassword, setConferePassword] = useState<boolean>(true);
  const [resetPasswordAuth, setResetPasswordAuth] = useState<string>("");
  const [resetPasswordStep, setResetPasswordStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [borderColor, setBorderColor] = useState<string>("white");

  const [doisFatoresTimeout, setDoisFatoresTimeout] = useState<number>(
    Date.now() + 10000
  );
  const [resetSenhaTimeout, setResetSenhaTimeout] = useState<number>(
    Date.now() + 10000
  );

  const timer = (
    <Countdown
      date={new Date(doisFatoresTimeout)}
      daysInHours
      onComplete={() => {
        setDoisFatores(false);
      }}
    />
  );

  const timerSenha = (
    <Countdown
      date={new Date(resetSenhaTimeout)}
      daysInHours
      onComplete={() => {
        setResetPasswordStep(0);
      }}
    />
  );
  const { setAuthUser } = useAuth();
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContainerRef.current &&
        !modalContainerRef.current.contains(event.target as Node)
      ) {
        setModalOpen(false);
      }
    };

    // Adicione o ouvinte de evento quando o componente for montado
    document.addEventListener("mousedown", handleClickOutside);

    // Remova o ouvinte de evento quando o componente for desmontado
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setModalOpen]);

  const entrar = () => {
    setLoading(true);
    const params = {
      username: (document.getElementById("email") as HTMLInputElement).value,
      password: (document.getElementById("pass") as HTMLInputElement).value,
    };
    doLogin(params, (response: any) => {
      if (response.status === 200) {
        const token = response.headers.get("Authorization");
        setDoisFatoresTimeout(parseJwt(token).exp * 1000);
        UserToken.email = params.username;
        UserToken.pass = params.password;
        UserToken.auth = token;
        setDoisFatores(true);
      }
      setLoading(false);
    });
  };

  const confirmar = () => {
    setLoading(true);
    const remind = (document.getElementById("lembrar") as HTMLInputElement).checked;
    const params = {
      code:
        (document.getElementById("tokenCode1") as HTMLInputElement).value +
        (document.getElementById("tokenCode2") as HTMLInputElement).value +
        (document.getElementById("tokenCode3") as HTMLInputElement).value +
        (document.getElementById("tokenCode4") as HTMLInputElement).value +
        (document.getElementById("tokenCode5") as HTMLInputElement).value +
        (document.getElementById("tokenCode6") as HTMLInputElement).value,
      remind: remind,
    };

    secondStep(params, (response: any) => {
      if (response.status === 200) {
        setAuthUser({
          token: response.headers.get("Authorization"),
          reminder: response.headers.get("Remind")
        });
        setModalOpen(false);
      } else {
        setTitle("Erro.");
        setMessage("Código inválido.");
        setConfirm("OK");
      }
      setLoading(false);
    });
  };

  const sendRequest = () =>{
    setLoading(true);
    const params = {
      email: (document.getElementById("email") as HTMLInputElement).value,
    };
    setEmail(params.email);
    sendPasswordResetRequest(params, (response: any) => {
      console.log(response);
      if (response.status === 200) {
        setResetPasswordStep(1);
        setResetSenhaTimeout(Date.now() + 180000);
      }
      setLoading(false);
    });
  }

  const sendCode = () =>{
    setLoading(true);
    const params = {
      email: email,
      code: 
      (document.getElementById("tokenSenha1") as HTMLInputElement).value +
      (document.getElementById("tokenSenha2") as HTMLInputElement).value +
      (document.getElementById("tokenSenha3") as HTMLInputElement).value +
      (document.getElementById("tokenSenha4") as HTMLInputElement).value +
      (document.getElementById("tokenSenha5") as HTMLInputElement).value
    };
    setCode(params.code);
    sendPasswordResetCode(params, (response: any) => {
      console.log(response);
      
      if (response.status === 200) {
        setResetPasswordAuth(response.headers.get("Authorization"));
        setResetPasswordStep(2);
      }
      setLoading(false);
    });
  }
  function allCases(str:string) {
    const
        upper = /[A-Z]/.test(str),
        lower = /[a-z]/.test(str);

    return upper && lower;
}
function detectSpecialChar(str:string) {
  return str.indexOf("@")!=-1 ||
  str.indexOf("#")!=-1 ||
  str.indexOf("%")!=-1 ||
  str.indexOf("=")!=-1 ||
  str.indexOf("&")!=-1 ||
  str.indexOf("!")!=-1;
}
  const sendConfirm = () =>{
    const pass1 = (document.getElementById("pass1") as HTMLInputElement).value;
    const pass2 = (document.getElementById("pass2") as HTMLInputElement).value;
    setConferePassword(pass1===pass2);
    if(pass1===pass2){
      setBorderColor("red");
    }else{
      setBorderColor("white");
    }

    if(pass1.length<8||!allCases(pass1)||!detectSpecialChar(pass1)){
      const msg = "Sua nova senha precisa ter no mínimo 08 caracteres, dos quais deve conter: 1 letra maiúscula, 1 letra minúscula, 1 número e 1 símbolo especial entre: @, #, %, =, &, !";

      setTitle("Ops...");
      setMessage(msg);
      setConfirm("OK");
      return;
    }

    if(pass1===pass2){
      setLoading(true);
      const params = {
        email: email,
        code: code,
        password:pass1,
        auth: resetPasswordAuth
      };
      sendPasswordResetConfirmation(params, (response: any) => {
        console.log(response);
        if (response.status === 200) {
          setResetPasswordStep(3);
        }
        setLoading(false);
      });
    }
  }

  const links = <div style={{ fontSize: "15px" }}>
  Ainda não tem conta?{" "}
  <button
    style={{
      backgroundColor: "transparent",
      borderStyle: "none",
      color: "white",
      fontWeight: "bold",
    }}
    onClick={() => {
      setModalOpen(false);
      setRegisterOpen(true);
    }}
  >
    Registre-se
  </button>
</div>
  const linksRecSenha = <div style={{ fontSize: "15px" }}>
  Esqueceu a senha?{" "}
  <button
    style={{
      backgroundColor: "transparent",
      borderStyle: "none",
      color: "white",
      fontWeight: "bold",
    }}
    onClick={() => {
      setResetPassword(true);
      setResetPasswordStep(0);
    }}
  >
    Clique aqui
  </button>
</div>

  return (
    <div
      style={{
        zIndex: "2000",
        position: "absolute",
        top: "0px",
        right: "0px",
        width: "400px",
        height: "400px",
        backgroundColor: "#22CC2288",
        overflow: "hidden",
        borderBottomLeftRadius: "16px",
      }}
      ref={modalContainerRef}
    >
      <div style={{ position: "relative" }}>
        {message !== "" ? (
          <div
            style={{
              position: "fixed",
              top: "calc(50% - 150px)",
              left: "calc(50% - 150px)",
              width: "300px",
              height: "200px",
              backgroundColor: "black",
              borderRadius: "15px",
              borderColor: "green",
              borderStyle: "solid",
              zIndex: "100000",
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: "24px",
                color: "white",
                fontWeight: "bold",
                paddingTop: "10px",
              }}
            >
              {title}
            </div>
            <div
              style={{
                width: "calc(100% - 20px)",
                height: "90px",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px",
                fontSize: "16px",
                color: "white",
              }}
            >
              {message}
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button
                style={{
                  padding: "10px",
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: "5px",
                  borderColor: "transparent",
                  borderWidth: "1px",
                  width: "80px",
                }}
                onClick={() => {
                  setMessage("");
                }}
              >
                {confirm}
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      {!doisFatores ?
        resetPassword ? (
          resetPasswordStep === 0 ? (
            <div style={{ position: "relative" }}>
              <div
                className="blurry"
                style={{
                  width: "400px",
                  height: "400px",
                  borderColor: "transparent",
                  position: "absolute",
                  zIndex: 0,
                  borderBottomLeftRadius: "16px",
                }}
              ></div>
              <div
                style={{
                  zIndex: 0,
                  position: "absolute",
                  width: "100%",
                  color: "white",
                  paddingTop: "50px",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                <div style={{ paddingBottom: "20px" }}>Acesse Sua Conta</div>
                <RoundWhiteInput
                    label="E-mail"
                    type="text"
                    id="email"
                    defaultValue={UserToken.email}
                  />
                  <button
                    style={{
                      borderRadius: "25px",
                      padding: "10px",
                      width: "280px",
                      backgroundColor: loading ? "gray" : "black",
                      color: loading ? "black" : "white",
                      fontWeight: "bold",
                      fontSize: "20px",
                      borderColor: "lightgray",
                      borderStyle: "solid",
                      borderWidth: "1px",
                    }}
                    disabled={loading}
                    onClick={sendRequest}
                  >
                    {" "}
                    {loading ? "Loading..." : "Recuperar senha"}
                  </button>
                <hr
                  style={{ width: "200px", height: "1px", borderStyle: "none" }}
                  color="#CCCCCCAA"
                />
                {links}
              </div>
            </div>
          ) : resetPasswordStep === 1 ? (
            
            <div
              style={{ position: "relative", maxHeight: "400px", height: "100%" }}
            >
            <div
              className="blurry"
              style={{
                width: "400px",
                height: "400px",
                borderColor: "transparent",
                position: "absolute",
                zIndex: 1,
                top: 0,
                borderBottomLeftRadius: "16px",
              }}
            ></div>
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                margin: "auto",
                maxHeight: "360px",
                justifyContent: "center",
                alignContent: "flex-start",
                height: "100%",
                color: "white",
                padding: "20px",
                gap: "8px",
                zIndex: 999,
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: "20px", paddingLeft:"40px" }}>
                Redefinir senha
              </div>
              <div style={{ fontSize: "14px", paddingLeft:"40px" }}>
                Para poder redefinir sua senha digite abaixo o token enviado para
                seu e-mail:
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginInline: "auto",
                  justifyContent: "center",
                  paddingBlock: "16px",
                  gap: "5px",
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                Token:
                </div>
                <CodeFieldSenha id="tokenSenha" />
                <div style={{ fontSize: "12px" }}>
                  {"Tempo limite para validação do token: "}
                  <span style={{ textDecoration: "underline" }}> {timerSenha}</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginInline: "auto",
                  justifyContent: "center",
                  paddingTop: "8px",
                  gap: "8px",
                }}
              >
                <button
                  style={{
                    borderRadius: "25px",
                    padding: "10px",

                    width: "280px",
                    backgroundColor: loading ? "gray" : "black",
                    color: loading ? "black" : "white",
                    fontWeight: "bold",
                    fontSize: "20px",
                    borderColor: "lightgray",
                    borderStyle: "solid",
                    borderWidth: "1px",
                  }}
                  disabled={loading}
                  onClick={sendCode}
                >
                  {loading ? "Loading..." : "Confirmar token"}
                </button>
                <button
                  key="c-2"
                  className={`button hovered`}
                  onClick={() => {
                    setResetPasswordStep(0);
                  }}
                >
                  {"Voltar"}
                </button>
              </div>
            </div>
          </div>

          ) : resetPasswordStep === 2 ?(
            <div style={{ position: "relative" }}>
              <div
                className="blurry"
                style={{
                  width: "400px",
                  height: "400px",
                  borderColor: "transparent",
                  position: "absolute",
                  zIndex: 0,
                  borderBottomLeftRadius: "16px",
                }}
              ></div>
              <div
                style={{
                  zIndex: 0,
                  position: "absolute",
                  width: "100%",
                  color: "white",
                  paddingTop: "50px",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                <div style={{ paddingBottom: "20px" }}>Definir Nova Senha</div>
                <div style={{ paddingBottom: "20px", fontSize:"16px", textAlign:"left", paddingLeft:"60px" }}>Digite e confirme sua nova senha</div>
                <RoundWhiteInput
                    label="Nova Senha"
                    type="password"
                    id="pass1"
                    borderColor={borderColor}
                    defaultValue={UserToken.pass}
                  />
                  <RoundWhiteInput
                    label="Confirmar Nova Senha"
                    type="password"
                    id="pass2"
                    borderColor={borderColor}
                    defaultValue={UserToken.pass}
                  />
                  {!conferePassword &&
                    <div style={{
                      zIndex: 0,
                      width: "100%",
                      color: "white",
                      backgroundColor:"#FF000066",
                      fontSize: "12px",
                      textAlign: "center",
                      marginBottom:"16px",
                      fontWeight:"bold",
                    }}>Senhas não conferem</div>
                    }
                  <button
                    style={{
                      borderRadius: "25px",
                      padding: "10px",
                      width: "280px",
                      backgroundColor: loading ? "gray" : "black",
                      color: loading ? "black" : "white",
                      fontWeight: "bold",
                      fontSize: "20px",
                      borderColor: "lightgray",
                      borderStyle: "solid",
                      borderWidth: "1px",
                    }}
                    disabled={loading}
                    onClick={sendConfirm}
                  >
                    {" "}
                    {loading ? "Loading..." : "Salvar"}
                  </button>
              </div>
            </div>
          ):(
            <div style={{ position: "relative" }}>
              <div
                className="blurry"
                style={{
                  width: "400px",
                  height: "400px",
                  borderColor: "transparent",
                  position: "absolute",
                  zIndex: 0,
                  borderBottomLeftRadius: "16px",
                }}
              ></div>
              <div
                style={{
                  zIndex: 0,
                  position: "absolute",
                  width: "100%",
                  color: "white",
                  paddingTop: "50px",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                <div style={{ paddingBottom: "0px" }}>Senha Redefinida</div>
                <div style={{ paddingBottom: "20px" }}>com sucesso!</div>
                <div style={{ paddingBottom: "20px", fontSize:"16px", textAlign:"left", paddingLeft:"60px" }}>Clique no botão voltar para voltar para tela de login.</div>
                
                <button
                    style={{
                      borderRadius: "25px",
                      padding: "10px",
                      width: "280px",
                      backgroundColor: loading ? "gray" : "black",
                      color: loading ? "black" : "white",
                      fontWeight: "bold",
                      fontSize: "20px",
                      borderColor: "lightgray",
                      borderStyle: "solid",
                      borderWidth: "1px",
                    }}
                    disabled={loading}
                    onClick={()=>{
                      setResetPassword(false);
                    }}
                  >
                    {" "}
                    {loading ? "Loading..." : "Voltar"}
                </button>
              </div>
            </div>
          )
        ) : (
          <div style={{ position: "relative" }}>
            <div
              className="blurry"
              style={{
                width: "400px",
                height: "400px",
                borderColor: "transparent",
                position: "absolute",
                zIndex: 0,
                borderBottomLeftRadius: "16px",
              }}
            ></div>
            <div
              style={{
                zIndex: 0,
                position: "absolute",
                width: "100%",
                color: "white",
                paddingTop: "50px",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              <div style={{ paddingBottom: "20px" }}>Acesse Sua Conta</div>
              <RoundWhiteInput
                  label="E-mail"
                  type="text"
                  id="email"
                  defaultValue={UserToken.email}
                />
                <RoundWhiteInput
                  label="Senha"
                  type="password"
                  id="pass"
                  defaultValue={UserToken.pass}
                />
                <button
                  style={{
                    borderRadius: "25px",
                    padding: "10px",
                    width: "280px",
                    backgroundColor: loading ? "gray" : "black",
                    color: loading ? "black" : "white",
                    fontWeight: "bold",
                    fontSize: "20px",
                    borderColor: "lightgray",
                    borderStyle: "solid",
                    borderWidth: "1px",
                  }}
                  disabled={loading}
                  onClick={entrar}
                >
                  {" "}
                  {loading ? "Loading..." : "Entrar"}
                </button>
              <hr
                style={{ width: "200px", height: "1px", borderStyle: "none" }}
                color="#CCCCCCAA"
              />
                {links}
                {linksRecSenha}
            </div>
          </div>
        ) : (
          <div
            style={{ position: "relative", maxHeight: "400px", height: "100%" }}
          >
            <div
              className="blurry"
              style={{
                width: "400px",
                height: "400px",
                borderColor: "transparent",
                position: "absolute",
                zIndex: 1,
                top: 0,
                borderBottomLeftRadius: "16px",
              }}
            ></div>
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                margin: "auto",
                maxHeight: "360px",
                justifyContent: "center",
                alignContent: "flex-start",
                height: "100%",
                color: "white",
                padding: "20px",
                gap: "8px",
                zIndex: 999,
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                Autenticação de Dois Fatores
              </div>
              <div style={{ fontSize: "14px" }}>
                Para poder acessar o sistema confirme abaixo o código enviado para
                seu e-mail cadastrado:
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginInline: "auto",
                  justifyContent: "center",
                  paddingBlock: "16px",
                  gap: "5px",
                }}
              >
                <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Código de Autenticação
                </div>
                <CodeField id="tokenCode" />
                <div style={{ fontSize: "12px" }}>
                  {"Tempo limite para validação do código: "}
                  <span style={{ textDecoration: "underline" }}> {timer}</span>
                </div>
              </div>
              <div style={{ marginTop: "8px" }}>
                <CheckBox
                  label="Lembrar deste dispositivo por 14 dias"
                  link=""
                  linkAction={() => { }}
                  id="lembrar"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginInline: "auto",
                  justifyContent: "center",
                  paddingTop: "8px",
                  gap: "8px",
                }}
              >
                <button
                  style={{
                    borderRadius: "25px",
                    padding: "10px",

                    width: "280px",
                    backgroundColor: loading ? "gray" : "black",
                    color: loading ? "black" : "white",
                    fontWeight: "bold",
                    fontSize: "20px",
                    borderColor: "lightgray",
                    borderStyle: "solid",
                    borderWidth: "1px",
                  }}
                  disabled={loading}
                  onClick={confirmar}
                >
                  {loading ? "Loading..." : "Confirmar código"}
                </button>
                <button
                  key="c-2"
                  className={`button hovered`}
                  onClick={() => {
                    setDoisFatores(false);
                  }}
                >
                  {"Voltar"}
                </button>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
