import sample from "../../../assets/videos/numberwinv1.mp4";
import icon_calendario from "../../../assets/images/icon_calendario.png";
import icon_cupons from "../../../assets/images/icon_cupons.png";
import icon_selecionar from "../../../assets/images/icon_selecionar.png";

import "../PaginaInicio.css";
import "../specialeffects.css";

import { WhiteTag } from "../Components/WhiteTag";

export interface RegisterData {
  nome: string;
  sobrenome: string;
  senha: string;
  email: string;
  telefone: string;
  cpf: string;
  dia: string;
  mes: string;
  ano: string;
}

export function PaginaSobre() {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div style={{ position: "relative" }}>
        <div style={{ height: "600px", overflow: "hidden" }}>
          <video
            className="videoTag"
            style={{ height: "100%" }}
            autoPlay
            loop
            muted
          >
            <source src={sample} type="video/mp4" />
          </video>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            backgroundColor: "#00000022",
          }}
        ></div>
      </div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "-180px",
            left: "0px",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              fontSize: 50,
              color: "white",
              paddingTop: "0px",
              paddingLeft: "12%",
              fontWeight: "bold",
            }}
          >
            Sobre a
          </div>
          <div
            style={{
              fontSize: 50,
              color: "white",
              paddingTop: "10px",
              paddingLeft: "12%",
              fontWeight: "bold",
              paddingBottom: "20px",
            }}
          >
            Number Win
          </div>
        </div>
        <div style={{ backgroundColor: "white", color: "black" }}>
          <div
            style={{
              fontWeight: "bold",
              paddingLeft: "12%",
              fontSize: "30px",
            }}
          >
            Sobre Nós
          </div>
          <div style={{ display: "flex", flexWrap:"wrap" }}>
            <div
              style={{
                paddingLeft: "12%",
                fontSize: "16px",
                minWidth: "60%",
                maxWidth: "60%",
                paddingRight: "30px",
              }}
            >
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
              <br />
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt.
              <br />
              <br />
              <br />
            </div>
            <div style={{ position: "relative" }}>
              <img
                src="phone2.png"
                style={{ position: "absolute", top: "0", left: "0" }}
                width="240px"
              />
            </div>
          </div>
        </div>
        <div className="part2" style={{height:"100%"}}>
          <div
            style={{
              paddingTop: "30px",
              paddingBottom: "30px",
              paddingLeft: "calc(50% - 135px)",
              fontWeight: "bold",
              fontSize: "30px",
              color: "white",
            }}
          >
            COMO FUNCIONA?
          </div>
          <div style={{ display: "flex", justifyContent: "space-around",flexWrap:"wrap" }}>
            <WhiteTag
              icon={icon_selecionar}
              title=" SELECIONAR SORTEIO"
              line="Você seleciona um sorteio do seu interesse"
            />
            <WhiteTag
              icon={icon_cupons}
              title=" COMPRAR CUPONS"
              line="Você decide a quantidade de númeroes que deseja comprar do sorteio, e paga de forma segura no nosso site."
            />
            <WhiteTag
              icon={icon_calendario}
              title=" AGUARDAR SORTEIO"
              line="Você aguarda a finalização do sorteio, ou a data marcada para o sorteio, conforme escrição, e confere o resultado."
            />
          </div>
        </div>
        <div
          style={{
            paddingLeft: "12%",
            fontSize: "16px",
            minWidth: "80%",
            maxWidth: "80%",
            paddingRight: "30px",
          }}
        >
          <div>
            <br />
            <br />
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt.
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
