import { useRef, useState } from "react";
import { CloseOutside } from "./Util";

interface Props {
  title: string;
  buttons: string[];
  inverted?: boolean;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}

export function DropdownSelectionButton_M({
  title,
  buttons,
  selectedIndex,
  setSelectedIndex,
}: Props) {
  const [open, setOpen] = useState(false);
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  CloseOutside(modalContainerRef, setOpen);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto",
        backgroundColor: "#22AA22",
        borderStyle: "none",
        borderRadius: "15px",
      }}
      ref={modalContainerRef}
    >
      <div>
        <button
          style={{
            color: "white",
            backgroundColor: "#22AA22",
            borderStyle: "none",
            borderRadius: "10px",
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
            textAlign:"center",
            fontSize: "10px",
            whiteSpace: "nowrap",
          }}
          onClick={() => {
            setOpen(!open);
            setSelectedIndex(-1);
          }}
        >
          {selectedIndex === -1 ? title : buttons[selectedIndex]}
        </button>
      </div>
      {open ? (
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              borderRadius: "10px",
              borderColor: "#22AA22",
              borderStyle: "solid",
              backgroundColor: "#22AA22",
              color: "white",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <div>
              {buttons.map((bt, index) => (
                <div key={title + "_ddb_" + index}>
                  {index > 0 ? (
                    <hr
                      style={{ width: "100%", padding: "0px", margin: "0px" }}
                      color="white"
                    />
                  ) : (
                    <div />
                  )}
                  <button
                    style={{
                      borderRadius: "10px",
                      borderColor: "transparent",
                      backgroundColor: "transparent",
                      color: "white",
                      width: "100%",
                      fontSize: "10px",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      setSelectedIndex(index);
                      setOpen(false);
                    }}
                  >
                    {bt}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
