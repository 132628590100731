function removeNumberMask(string?: any) {
  return String(string).replace(/\D/g, "");
}

function validateCPF(cpf: string) {
  // ONLY FOR ADMIN USER
  if (cpf === "000.000.000-00" || cpf === "00000000000") {
    return null;
  }
  if (!cpf) {
    return "Insira um número de CPF";
  }
  let clearCpf = "";
  let errorMessage = "O CPF digitado não está no formato válido";
  if (cpf) {
    clearCpf = removeNumberMask(cpf);
  } else {
    return "Insira um número de CPF";
  }
  let sum = 0;
  let rest;
  if (
    clearCpf.length !== 11 ||
    clearCpf === "00000000000" ||
    clearCpf === "11111111111" ||
    clearCpf === "22222222222" ||
    clearCpf === "33333333333" ||
    clearCpf === "44444444444" ||
    clearCpf === "55555555555" ||
    clearCpf === "66666666666" ||
    clearCpf === "77777777777" ||
    clearCpf === "88888888888" ||
    clearCpf === "99999999999"
  ) {
    return errorMessage;
  }
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(clearCpf.substring(i - 1, i), 10) * (11 - i);
  }
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(clearCpf.substring(9, 10), 10)) {
    return errorMessage;
  }
  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(clearCpf.substring(i - 1, i), 10) * (12 - i);
  }
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) {
    rest = 0;
  }
  if (rest !== parseInt(clearCpf.substring(10, 11), 10)) {
    return errorMessage;
  }
  return null;
}

const validateCNPJ = (cnpj: string) => {
  let clearCnpj = "";
  let errorMessage = "O CNPJ digitado não está no formato válido";
  if (cnpj) {
    clearCnpj = cnpj.replace(/[^\d]/g, "");
  } else {
    return "Insira um número de CNPJ";
  }

  if (clearCnpj === "") {
    return "Insira um número de CNPJ";
  }

  if (clearCnpj.length !== 14) {
    return errorMessage;
  }

  if (
    clearCnpj === "00000000000000" ||
    clearCnpj === "11111111111111" ||
    clearCnpj === "22222222222222" ||
    clearCnpj === "33333333333333" ||
    clearCnpj === "44444444444444" ||
    clearCnpj === "55555555555555" ||
    clearCnpj === "66666666666666" ||
    clearCnpj === "77777777777777" ||
    clearCnpj === "88888888888888" ||
    clearCnpj === "99999999999999"
  ) {
    return errorMessage;
  }

  let size = clearCnpj.length - 2;
  let numbers = clearCnpj.substring(0, size);
  const digits = clearCnpj.substring(size);

  let sum = 0;
  let position = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i), 10) * position--;
    if (position < 2) {
      position = 9;
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(0), 10)) {
    return errorMessage;
  }

  size += 1;
  numbers = clearCnpj.substring(0, size);
  sum = 0;
  position = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i), 10) * position--;
    if (position < 2) {
      position = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(1), 10)) {
    return errorMessage;
  }

  return null;
};

function isEmailValid(email: string) {
  const isValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

  return isValid
    ? null
    : "O campo não deve ser vazio e deve conter um e-mail válido.";
}

function checkPhone(cellphone: any) {
  let errorMessage =
    "O campo deve ser preenchido com DDD e número ou DDI, DDD e número.";
  if (!cellphone) {
    return errorMessage;
  }

  const number = removeNumberMask(cellphone);

  // Validar comprimento de número com DDI (13 ou 14 dígitos) ou sem DDI (10 ou 11 dígitos)
  const isCellphoneValid =
    number.length === 10 ||
    number.length === 11 ||
    number.length === 13 ||
    number.length === 14;

  if (!isCellphoneValid) {
    return errorMessage;
  }

  // Extraímos o DDD baseado na presença ou ausência do DDI
  let dddIndex =
    number.length === 10 || number.length === 11 ? 0 : number.length - 11;
  const ddd = Number(number.substring(dddIndex, dddIndex + 2));
  const isDDDValid = ddd >= 11 && ddd <= 99;

  if (!isDDDValid) {
    errorMessage = "Deve ser informado um DDD válido (entre 11 e 99).";
    return errorMessage;
  }

  // Se tiver DDI, verificar se o DDI é válido
  if (number.length === 13 || number.length === 14) {
    const ddi = Number(number.substring(0, dddIndex));
    const isDDIValid = ddi > 0; // Aqui você pode definir regras mais específicas para DDI, se necessário
    if (!isDDIValid) {
      errorMessage = "Deve ser informado um DDI válido.";
      return errorMessage;
    }
  }

  return null; // Se passar todas as verificações
}

function isValidFullname(fullname: any) {
  const isFullnameValid =
    Boolean(fullname) &&
    fullname
      .trim()
      .match(/^[A-zÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-zÀ-Ÿ][A-zÀ-ÿ']+$/);

  return isFullnameValid
    ? null
    : "O campo deve conter um nome completo valido. Com nome, sobrenome e não pode conter números.";
}

function isValidCEP(cep: any) {
  let errorMessage = "O CEP informado não é valido";
  if (!cep) {
    return "Insira um número de CEP";
  }
  const withoutMask = removeNumberMask(cep);
  if (withoutMask) {
    if (withoutMask.length === 8) {
      return null;
    }
  }
  return errorMessage;
}

function isPassword(value: any) {
  const isValid =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#%=&!])[A-Za-z\d@#%=&!]{8,32}$/.test(
      value
    );
  return isValid
    ? null
    : "Senha deve conter: " +
        "\n\n•Entre 8 (oito) a 32 (Trinta e dois) caracteres;" +
        "\n\n•Composta por números;" +
        "\n\n•Letras (maiúsculas e minúsculas);" +
        "\n\n•Símbolos especiais (@, #, %, =, &, !).";
}

const validations = {
  validateCPF,
  validateCNPJ,
  isEmailValid,

  checkPhone,
  isValidFullname,
  isValidCEP,

  isPassword,
};

export default validations;
