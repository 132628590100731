import { useEffect, useRef } from "react";
import { MenuButton } from "./MenuButton";
import { RoundInput } from "./RoundInput";

export interface Props {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function NewCreditCardTab({ setModalOpen }: Props) {
  const modalContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalContainerRef.current &&
        !modalContainerRef.current.contains(event.target as Node)
      ) {
        setModalOpen(false);
      }
    };

    // Adicione o ouvinte de evento quando o componente for montado
    document.addEventListener("mousedown", handleClickOutside);

    // Remova o ouvinte de evento quando o componente for desmontado
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setModalOpen]);

  return (
    <div>
      <div
        style={{
          zIndex: "2000",
          position: "fixed",
          top: "100px",
          right: "calc(50% - 200px)",
          width: "400px",
          height: "560px",
          backgroundColor: "#FFFFFF",
          borderRadius: "20px",
          borderStyle: "solid",
          borderColor: "black",
          borderWidth: "1px",
          overflow: "hidden",
        }}
        ref={modalContainerRef}
      >
        <div style={{ position: "relative" }}>
          <div
            style={{
              zIndex: 0,
              position: "absolute",
              width: "100%",
              color: "black",
              paddingTop: "10px",
              textAlign: "center",
            }}
          >
            <img src="shield.png" />
            <div style={{ fontSize: "24px", paddingBottom: "20px" }}>
              Novo Cartão de Crédito
            </div>
            <hr style={{ width: "80%" }} />
            <RoundInput
              label={"Nome do Titular do Cartão"}
              type={"text"}
              id={"card_owner"}
            />
            <RoundInput label={"CPF"} type={"cpf"} id={"card_cpf"} />
            <RoundInput
              label={"Número do cartão"}
              type={"card"}
              id={"card_number"}
            />
            <div style={{ display: "flex", paddingLeft: "60px" }}>
              <RoundInput
                label={"Data de expiração"}
                type={"date"}
                id={"card_exp_date"}
                width={"145px"}
              />
              <div style={{ paddingLeft: "25px" }}></div>
              <RoundInput
                label={"CVC"}
                type={"text"}
                id={"card_cvc"}
                width={"105px"}
                length={3}
              />
            </div>
            <div style={{ paddingLeft: "115px", width: "170px" }}>
              <MenuButton
                label="Adicionar Cartão"
                action={() => {
                  setModalOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          zIndex: "1000",
          position: "fixed",
          top: "0px",
          right: "0px",
          width: "100%",
          height: "100%",
          backgroundColor: "#00000088",
        }}
      ></div>
    </div>
  );
}
