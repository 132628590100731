import { Ticket } from "./Ticket";
import { Ticket_M } from "./Ticket_M";

interface Props {
  winnerName: string;
  winnerPhoto: any;
  winnerPosition: string;
  prizeName: string;
  prizeIcon: any;
  prizeCode: string;
  silver?: boolean;
  small?: boolean;
  smallTxtColor?: string;
}

export function WinnerCard_M({
  winnerName,
  winnerPhoto,
  winnerPosition,
  prizeName,
  prizeIcon,
  prizeCode,
  silver,
  small,
  smallTxtColor,
}: Props) {
  const color1 = silver ? "#bbbbbb" : "#b1955b";
  const color2 = silver ? "#bbbbbb55" : "#474128";
  const color3 = silver ? "#bbbbbb" : "#C1A162";

  return (
    <div
      style={{
        marginTop: small ? "40px" : "0px",
        width: small ? "232px" : "290px",
        maxWidth: small ? "232px" : "290px",
        height: small ? "255px" : "auto",
        backgroundColor: color2,
        padding: small ? "8px" : "10px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: small ? "24px" : "30px",
        marginLeft: small ? "24px" : "30px",
        borderColor: color3,
        position: "relative",
      }}
    >
      <img
        src={winnerPhoto}
        width={small ? "96px" : "120px"}
        height={small ? "96px" : "120px"}
        style={{
          position: "absolute",
          top: small ? "-24px" : "-30px",
          left: small ? "calc(50% - 48px)" : "calc(50% - 60px)",
          borderRadius: small ? "48px" : "60px",
          borderStyle: "solid",
          borderColor: color1,
          borderWidth: "1px",
        }}
      />
      <div
        style={{
          height: small ? "80px" : "100px",
        }}
      />
      <div
        style={{
          textAlign: "center",
          color: "white",
          fontWeight: "bold",
          fontSize: small ? "16px" : "20px",
        }}
      >
        {winnerName}
      </div>
      <div
        style={{
          textAlign: "center",
          color: "white",
          paddingBottom: small ? "8px" : "10px",
        }}
      >
        {winnerPosition}
      </div>
      <hr color={color1} />
      <div
        style={{
          height: "5px",
        }}
      />
      <Ticket_M
        prize={prizeName}
        icon={prizeIcon}
        codigo={prizeCode}
        silver={silver}
        small={small}
        ranking={"NÚMERO GANHADOR!"}
        smallTxtColor={smallTxtColor}
      />
    </div>
  );
}
