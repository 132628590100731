import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { useEffect, useState } from "react";
import relampago from "../../../assets/images/relampago.png";
import { getFlashCampaign } from "../../../services/login";
import { UserToken } from "../../../services/UserToken";
import { Messager } from "../MainPage";
import { AllRafflesResult, FillRaffleImages } from "../PaginaInicio";
import { CardProps } from "./Card";
import { useAuth } from "../../../contexts/AuthContext";
import dollar_sign1 from "../../../assets/images/Dollar_Sign_1.png";

export interface CampanhaRelampagoProps {
  menuHeight?: any;
}

export function CampanhaRelampago_M({ menuHeight }: CampanhaRelampagoProps) {
  const [campanha, setCampanha] = useState<CardProps>();
  const { hasPermission } = useAuth();

  useEffect(() => {
    getFlashCampaign((rest: AllRafflesResult) => {
      const data: CardProps[] = [];
      for (let index = 0; index < rest.content.length; index++) {
        const element = rest.content[index];

        FillRaffleImages(element, () => {
          data.push({
            raffle: element,
          });
          setCampanha(data[0]);
        });
      }
    });
  }, []);
  if (!campanha) {
    return null;
  }
  return (
    <div
      style={{
        backgroundColor: "green",
        width: "195px",
        borderRadius: "20px",
        margin: "5px",
        padding: "3px",
        position:"relative",
      }}
    >
      <div style={{position:"absolute", width:"100%", height:"250px", zIndex:"-50",  top:"-60px",left:"100px"}}>
        <div style={{display:"flex"}}>
          <img
            src={dollar_sign1}
            style={{
              paddingLeft:"30%",
              pointerEvents: "none",
            }}
            width="150px"
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "green",
          borderRadius: "15px",
          borderColor: "white",
          borderStyle: "solid",
          borderWidth: "1px",
          margin: "5px",
          padding: "10px",
          paddingRight: "150px",
        }}
      >
        <div style={{ position: "relative", display: "flex", flex: "1", width:"100%" }}>
          <img
            src={relampago}
            style={{
              position: "absolute",
              left: "100px",
              top: "-25px",
              width: "100px",
            }}
          />
        </div>
        <div
          style={{
            color: "white",
            fontSize: "10px",
            fontWeight: "bold",
            flexWrap:"nowrap",
            width:"150px"
          }}
        >
          CAMPANHA <br/>RELÂMPAGO
        </div>
        <div
          style={{
            overflow: "clip",
            fontSize: "9px",
            color: "white",
            paddingTop: "5px",
          }}
        >
          {campanha?.raffle.description}
        </div>
        <div style={{ paddingBottom: "5px" }}></div>
        <div
          style={{
            backgroundColor: "black",
            borderRadius: "10px",
            borderColor: "#ffee9f",
            borderStyle: "solid",
            padding: "5px",
            color: "white",
            width:"113px"
          }}
        >
          <FlipClockCountdown
            to={new Date(campanha?.raffle.drawDateTime + "").getTime()}
            labels={["D", "HOUR", "MINUTE", "SECOND"]}
            labelStyle={{
              color: "#ffee9f",
              fontSize: 6,
              fontWeight: 500,
              textTransform: "uppercase",
            }}
            digitBlockStyle={{ width: 11, height: 16, fontSize: 12 }}
            separatorStyle={{ color: "red", size: "3px" }}
            renderMap={[false, true, true, true]}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "160px",
            paddingTop:"10px",
          }}
        >
          <div></div>
          <button
            style={{
              borderRadius: "10px",
              borderColor: "transparent",
              backgroundColor: "white",
              color: "green",
              fontWeight: "bold",
              fontSize:"10px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
            onClick={() => {
              if (hasPermission("ROLE_CLIENT")) {
                window.location.href =
                  window.location.href.substring(
                    0,
                    window.location.href.indexOf("?")
                  ) +
                  "?pag=8&raffleId=" +
                  campanha.raffle.id;
              } else {
                Messager.message(
                  "Ops...",
                  "É necessário estar logado para comprar cupons."
                );
              }
            }}
          >
            {campanha.raffle.isActive ? "COMPRAR CUPOM" : "CONFERIR SORTEIO"}
          </button>
          <div></div>
        </div>
      </div>
    </div>
  );
}
