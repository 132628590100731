function formatPhoneNumber(phone: string): string {
  const cleaned = ("" + phone).replace(/\D/g, "");

  const trimmed = cleaned.substring(0, 11);

  const match = trimmed.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
  }

  return phone;
}

function removeMaks(input: string): string {
  if (!input) {
    return "";
  } else {
    return input.replace(/[^a-zA-Z0-9]/g, "");
  }
}

const masks = {
  removeMaks,
  formatPhoneNumber,
};

export default masks;
