import { useRef, useState } from "react";
import { CloseOutside } from "./Util";

interface Props {
  title: string;
  buttons: ButtonActionProp[];
  inverted?: boolean;
}

export interface ButtonActionProp {
  label: string;
  action: () => void;
  inverted?: boolean;
}

export function DropdownMenuButton_M({ title, buttons, inverted }: Props) {
  const [open, setOpen] = useState(false);
  const modalContainerRef = useRef<HTMLDivElement | null>(null);
  CloseOutside(modalContainerRef, setOpen);

  if (!open) {
    return (
      <div
        style={{
          borderRadius: "18px",
          borderColor: "green",
          borderStyle: "solid",
          backgroundColor: inverted ? "#00000023" : "green",
  
          backdropFilter: inverted ? " blur(18px)" : "none",
          color: "white",
          paddingLeft: "28px",
          paddingRight: "28px",
          width: "150px",
        }}
      >
        <button
          style={{
            borderRadius: "18px",
            borderColor: "transparent",
            backgroundColor: "transparent",
            color: "white",
            width: "100%",
            padding: 0,
            margin: 0,

            fontSize: "18px",
            lineHeight: "28px",
          }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {title}
        </button>
      </div>
    );
  } else {
    return (
      <div
        style={{
          borderRadius: "18px",
          borderColor: "green",
          borderStyle: "solid",
          backgroundColor: "green",
          color: "white",
          paddingLeft: "28px",
          paddingRight: "28px",
          width: "150px",
        }}
        ref={modalContainerRef}
      >
          <button
            style={{
              borderRadius: "18px",
              borderColor: "transparent",
              backgroundColor: "transparent",
              color: "white",
              width: "100%",
              padding: 0,
              margin: 0,

              fontSize: "18px",
              lineHeight: "28px",
            }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {title}
          </button>
          <div>
            {buttons.map((bt, index) => (
              <div key={title + "_ddb_" + index}>
                {index > 0 ? (
                  <hr
                    style={{ width: "100%", padding: "0px", margin: "0px" }}
                    color="white"
                  />
                ) : (
                  <div />
                )}
                <button
                  style={{
                    borderRadius: "18px",
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    color: "white",
                    width: "100%",
                    fontSize: "18px",
                    lineHeight: "28px",
                    padding: 0,
                    margin: 0,
                  }}
                  onClick={() => {
                    bt.action();
                    setOpen(false);
                  }}
                >
                  {bt.label}
                </button>
              </div>
            ))}
          </div>
        </div>
    );
  }
}
